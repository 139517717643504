.commerce-categories-products-block {
    .category_title {
        background-color: #fff;
        padding: 10px 40px 0;
        font-size: 20px;
        display: flex;
        color: #292228;
        justify-content: space-between;

        span {
            font-size: 15px;
            margin-top: 5px;
            color: #999999;

            &:hover {
                color: #d1005e;
            }
        }
    }
}
