@import '../../../../variables.scss';

.splitted__payment {
    margin-top: 24px;
    .order__summary {
        .order__list {
            list-style: none;
            padding: 0;
            .order__item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 8px;
                padding: 0;
                &--info {
                    display: flex;
                    align-items: center;
                    .CheckBox {
                        min-width: 18px;
                    }
                }
            }
        }
    }

    .payment__total {
        .text-small {
            font-size: 10px;
        }
    }
}

.tips__container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
    flex-wrap: wrap;

    .tip__option {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border: 1px solid $primaryColorLight;
        border-radius: 8px;
        font-weight: 500;
        flex-grow: 1;
        padding: 8px;
        cursor: pointer;

        &:hover , &.active {
            background-color: $primaryColorLight;
            color: $accentColor;
            border: 1px solid $accentColor
        }
    }

    .tip__option.custom__tip {
        width: 100%;
    }
}

