#commerce-confirmation {
    background-color: #fff;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
    user-select: none;

    &,
    #frame {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    #frame {
        background-color: #eeeeee;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        border: 1px solid #dddddd;
        padding: 30px 10px;
        text-align: center;
        height: 100%;
        position: relative;
        overflow: hidden;
        width: 100%;

        @media (min-width: 768px) {
            max-height: 650px;
            width: auto;
            border-radius: 20px;
            margin: 5px 0;
        }

        h1 {
            background-color: #d1005e;
            margin-bottom: 20px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }

        .back {
            margin: 10px 0;
        }

        h5 {
            margin-bottom: 20px;
        }

        h6 {
            background-color: #dddddd;
            margin: 0;
            padding: 10px;
            border-radius: 10px 10px 0 0;
            width: 100%;
        }

        .spinner-border {
            position: absolute;
            top: 40%;
            left: 50%;
            margin-left: -10px;
        }

        .logo {
            width: 150px;
            position: absolute;
            bottom: 0;
        }
    }

    #product-selector {
        width: 100%;
        height: 94%;

        @media (min-width: 768px) {
            width: 300px;
        }

        input {
            margin-bottom: 10px;
        }

        .list-group {
            height: 87%;
            overflow: auto;
        }
    }

    .status-1 {
        border-color: greenyellow !important;
        background-color: greenyellow !important;
    }

    .status-2 {
        border-color: red !important;
        background-color: red !important;
    }

    #scanner {
        background-color: #ffffff;
        width: 100%;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        border: 4px solid #dddddd;

        @media (min-width: 768px) {
            width: 300px;
        }

        video {
            transform: scaleX(-1);
        }

        > section {
            position: relative;

            &:after {
                content: '';
                width: 100%;
                border: 1px solid red;
                box-shadow: 0 0 5px red;
                position: absolute;
                top: 0;
                left: 0;
                animation: scanning 3s linear infinite;
            }

            @keyframes scanning {
                0% {
                    top: 0;
                }
                50% {
                    top: 100%;
                }
                100% {
                    top: 0%;
                }
            }
        }
    }

    .placeholder {
    }

    .features {
        display: flex;
        overflow: auto;
        margin-bottom: 10px;

        span {
            width: 100px;

            img {
                width: 40px;
                display: block;
                margin: 0 auto;
            }
        }
    }
}
