@import '../../../../variables.scss';

.product-box-block {
    box-sizing: border-box;
    height: 100%;
    background-color: #ffffff;
    padding: 5px;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    transition: border-color 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    // min-height: 350px;

    &:hover {
        background-color: #fbfbfb;
    }
    a {
        &:hover {
            color: $primaryColor;
        }
    }
    .top-section {
        display: flex;
        position: relative;
        justify-content: center;
        box-sizing: border-box;
        padding: 4px;
        margin-bottom: 16px;
        height: 250px;
        overflow: hidden;

        // flex: 1;

        .image {
            width: 100%;
            height: auto;
            object-fit: contain;
            display: flex;
            justify-content: center; // Center the image horizontally
            align-items: center; // Center the image vertically
            transition: transform 0.2s ease;
            background-size: contained;
        }

        .top-floaters {
            display: flex;
            justify-content: space-between;
            background-color: transparent;
            width: 100%;
            padding: 4px;
            position: absolute;
            top: 0px;

            .right-actions {
                display: flex;
                flex-direction: column;
                align-items: end;
                gap: 4px;

                .fav-icon-button,
                .add-to-compare,
                .go-to-compare {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    max-width: fit-content;
                    border: none;
                    // color: $accentColor;

                    background-color: white !important;
                    padding: 8px;
                    filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 0px 5px);
                    transition: transform 0.2s ease;
                    svg {
                        width: 18px;
                        height: 18px;
                        fill: none;
                        stroke: $primaryColor;
                    }
                    &.success {
                        svg {
                            fill: $accentColor;
                            stroke: $accentColor;
                        }
                    }

                    &:hover {
                        svg {
                            stroke: $extraColor1 !important;
                            fill: $extraColor1 !important;
                        }
                    }
                }
                .add-to-compare {
                    transition: transform 0.2s ease;
                    font-size: 12px;

                    &.success {
                        stroke: $accentColor;
                    }
                }

                .compare-buttons-wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    background-color: white;
                    gap: 4px;
                    border: 2px solid $accentColor;
                    padding: 2px;
                    border-radius: 5px;

                    .go-to-compare {
                        color: $primaryColor;
                        &:hover {
                            color: $extraColor1;
                        }
                    }
                }
            }
            .badges {
                display: flex;
                flex-direction: column;
                gap: 2px;
                max-width: fit-content;

                .badge {
                    max-width: fit-content;
                    .amount {
                        display: none;
                    }
                }
            }
        }
        .bottom-floaters {
            display: flex;
            justify-content: space-between;
            align-items: end;
            width: 100%;
            padding: 0px;
            position: absolute;
            padding: 4px;
            left: 0px;
            bottom: 0px;

            .rating {
                display: flex;
                white-space: nowrap;
                background-color: white;
                font-size: 14px;
                border-radius: 16px;
                padding: 2px 8px;
                filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 0px 5px);
                color: #ff9800;
                display: block;
            }

            .add-to-cart-action {
                .button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    max-width: fit-content;
                    border: none;
                    background-color: white !important;
                    padding: 8px;
                    filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 0px 5px);

                    span {
                        display: none;
                    }
                }

                svg {
                    width: 22px;
                    height: 22px;
                    stroke: $primaryColor;
                    fill: $primaryColor;
                }
                &:hover {
                    svg {
                        stroke: $extraColor1;
                        fill: $extraColor1;
                    }
                }

                .button-custom {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    max-width: fit-content;
                    border: none;
                    gap: 6px;
                    // background-color: white !important;
                    padding: 8px;
                    filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 0px 5px);
                    color: white;
                    font-size: small;

                    svg {
                        width: 22px;
                        height: 22px;
                        stroke: white;
                        fill: white;
                    }
                    &:hover {
                        .button {
                            color: $extraColor1;
                        }
                        svg {
                            stroke: $extraColor1;
                            fill: $extraColor1;
                        }
                    }
                }
            }
        }
    }

    .bottom-section {
        width: 100%;
        padding: 0 4px;
        height: 90px;

        .title {
            display: -webkit-box; /* Enable flexbox for WebKit browsers */
            line-clamp: 2;
            -webkit-line-clamp: 2; /* Number of lines to show */
            -webkit-box-orient: vertical; /* Set box orientation to vertical */
            overflow: hidden; /* Hide the rest of the content */
            text-overflow: ellipsis; /* Add ellipsis after the specified lines */
            white-space: normal;
            font-size: 14px;
            min-height: 42px;
        }

        // .description {
        // 	color: gray;
        // 	// height: 50px;
        // 	overflow: hidden;
        // 	line-height: 25px;
        // }

        .price {
            display: flex;
            flex-wrap: wrap;
            font-size: 11px;
            font-weight: normal;
            align-items: center;
            margin-top: 8px;
            gap: 0px;

            span {
                font-size: 13px;
                font-weight: bold;
            }
            .price-amount {
                display: flex;
                align-items: center;
                gap: 4px;
            }
            .old-price {
                color: red;
                text-decoration: line-through;
            }
        }

        .starting_from {
            color: #aaaaaa;
            margin-right: 4px;
        }

        .toggleTheme__actions {
            display: none;
        }
    }

    @media (max-width: 750px) {
        .top-section {
            height: 220px;

            .top-floaters {
                .right-actions {
                    .fav-icon-button,
                    .add-to-compare,
                    .go-to-compare {
                        padding: 4px;
                        svg {
                            width: 16px;
                            height: 16px;
                        }
                    }
                    .compare-buttons-wrapper {
                        gap: 4px;
                        border: 1px solid $accentColor;
                        padding: 1px;
                    }
                }
            }
            .bottom-floaters {
                .rating {
                    padding: 2px 4px;
                    font-size: 12px;
                }

                .add-to-cart-action {
                    svg {
                        width: 18px;
                        height: 18px;
                    }
                }
            }
        }
        .bottom-section {
            // height: 40px;
            .title {
                margin: 10px 0;
                font-size: 12px;
                margin-bottom: 0;
            }
        }
    }

    &.toggleTheme {
        .top-section {
            height: 220px;
            .right-actions {
                display: none;
            }
            .top-floaters {
                .badges {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    gap: 4px;
                }
            }
            .bottom-floaters {
                display: none;
            }
        }
        .bottom-section {
            height: fit-content;
            .title {
                text-align: center;
            }
            .price {
                text-align: center;
                justify-content: center;
            }
            .toggleTheme__actions {
                padding-top: 12px;
                display: flex;
                align-items: stretch;
                gap: 4px;
                flex-wrap: nowrap;
                justify-content: space-between;
                
                .fav-icon-button,
                .add-to-compare,
                .go-to-compare {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    max-width: fit-content;
                    border: none;
                    padding: 8px;
                    height: 100%;
                    filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 0px 5px);
                    transition: transform 0.2s ease;
                    svg {
                        width: 18px;
                        height: 18px;
                        fill: none;
                        stroke: white;
                    }
                    &.success {
                        svg {
                            fill: white;
                            stroke: white;
                        }
                    }

                    &:hover {
                        svg {
                            stroke: $extraColor1 !important;
                            fill: $extraColor1 !important;
                        }
                    }
                }

                .go-to-compare i {
                    color: white;
                }

                button.fav-icon-button  {
                    height: auto;
                }
               
                .add-to-compare {
                    transition: transform 0.2s ease;
                    font-size: 12px;
                    height: 100%;                    

                    &.success {
                        stroke: $accentColor;
                    }
                }

                .compare-buttons-wrapper {
                    display: flex;
                    // flex-direction: column;
                    flex-flow: row-reverse;
                    align-items: stretch;
                    background-color: white;
                    gap: 4px;
                    border: 2px solid $accentColor;
                    padding: 2px;
                    border-radius: 5px;

                    .go-to-compare {
                        color: $primaryColor;
                        &:hover {
                            color: $extraColor1;
                        }
                    }
                }

                .add-to-cart-action {
                    flex-grow: 1;
                    height: auto;
                    .button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: none;
                        filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 0px 5px);
                        width: 100%;
                        padding: 8px;
                        font-size: 12px;
                        height: 100%;
                    }
    
                    svg {
                        width: 25px;
                        height: 25px;
                        stroke: white;
                        fill: white;
                        margin-inline-end: 4px;
                    }
                    &:hover {
                        svg {
                            stroke: $extraColor1;
                            fill: $extraColor1;
                        }
                    }
    
                    .button-custom {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: none;
                        gap: 6px;
                        filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 0px 5px);
                        color: white;
                        font-size: small;
                        width: 100%;
                        svg {
                            width: 22px;
                            height: 22px;
                            stroke: white;
                            fill: white;
                        }
                        &:hover {
                            .button {
                                color: $extraColor1;
                            }
                            svg {
                                stroke: $extraColor1;
                                fill: $extraColor1;
                            }
                        }
                    }
                }

            }
        }
    }
}
