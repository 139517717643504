@import '../../../../variables.scss';

.summary-modal {
}

.modal-90w {
   
    @media (max-width: 768px) {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
    }
}

.modal-dialog {
    // margin: unset;
}

.modal-header {
    justify-content: space-between;
    .btn-close {
        margin: unset;
    }

    .modal__header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &--title {
            font-size: 20px;
            font-weight: 600;
            margin: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }
}

// DIVIDED MODAL
.divided__list {
    list-style: none;
    padding: 0;
    margin: 0;
    min-height: 50vh;
    .divided__option {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        &--info {
            display: flex;
            align-items: center;
            gap: 2px;
        }
        &--counter {
            display: flex;
            align-items: center;
            gap: 2px;
            .badge {
                cursor: pointer;
                opacity: 0.8;
                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}

.divided__footer {
    width: 100%;
}


.payment-methods-modal {
    .method {
        border: 1px solid #eeeeee;
        border-radius: 10px;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        flex-wrap: wrap;

        &:hover {
            background-color: #efefef;
            cursor: pointer;
        }

        img {
            height: 25px;
        }

        .cost {
            margin-right: auto;
            color: $primaryColor;
        }

        .payment-hook {
            width: 1000px;
            display: none;

            > div {
                background-color: transparent;
            }
        }

        &.selected .payment-hook {
            display: block;
        }
    }

}
