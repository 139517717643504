@import '../../../variables';

#order-details-page {
    margin: 20px auto;
    min-height: 450px;

    #loading {
        height: 450px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #order-actions {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        button {
            margin: 5px;
            position: relative;

            i {
                background: rgba(0, 0, 0, 0.3);
                width: 36px;
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .card {
        margin-bottom: 20px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
    }

    .steps {
        margin: 20px 20px 40px;
        color: #abb4cb;
        font-size: 10px;
        display: none;

        @media (min-width: 768px) {
            display: flex;
        }

        .step {
            position: relative;

            div {
                background-color: $primaryColor;
                width: 40px;
                height: 40px;
                border-radius: 40px;
                color: #ffffff;
                font-size: 17px;
                margin: 0 auto;
                font-weight: 600;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            span {
                position: absolute;
                width: 100px;
                text-align: center;
                left: 0;
                bottom: -20px;
                margin-left: -30px;
                color: $primaryColor;
            }

            &.active div {
                background-color: $accentColor;
                color: #ffffff;
            }
        }

        .line {
            height: 0;
            flex: 1;
            text-align: center;
            font-size: 7px;
            content: '';
            border-bottom: 1px dashed #dddddd;
            margin-top: 20px;
            display: flex;

            div {
                width: 50%;
                border: 2px solid $accentColor;
                border-radius: 2px;
                margin-top: -2px;
            }
        }
    }

    .item {
        align-items: center;
        margin-bottom: 10px;
    }

    td {
        padding: 5px;
    }

    .rating {
        color: #ccc;
        direction: ltr;
        font-size: 50px;

        span {
            padding: 1%;
            cursor: pointer;
        }

        .active,
        span:hover:before,
        span:hover ~ span:before {
            transition: color 0.5s;
            color: orange;
        }
    }

    .user_rating {
        .active {
            transition: color 0.5s;
            color: orange;
        }
    }

    @media print {
        background-color: #fff;
        //height: 100%;
        //width: 100%;
        //position: fixed;
        //top: 0;
        //left: 0;
        max-width: none;
        z-index: 9999999999999999;
        margin: 0;
        overflow: auto;

        img,
        hr,
        .steps,
        button,
        .no-print,
        .card-header .badge,
        .item .price {
            display: none;
        }

        .card {
            margin: 3px 0 0;
            border: 0;

            .card-header {
                background-color: #cccccc;
            }

            .card-header,
            .card-body {
                padding: 3px;
                border: 0;
            }
        }

        .form-control {
            padding: 0;
            border: 0;
            margin: 0;
        }
    }

    //print shipping template css
    .print-only {
        display: none;
        max-width: 50%;

        th {
            padding: 0 12px;
            width: fit-content;
        }
        // td {
        //     border-bottom: 1px solid #ccc;
        // }
    }
    .print-label-button {
        margin-top: 8px;
    }
    @media print {
        .print-only {
            max-width: 50%;

            th {
                padding: 0 12px;
                width: fit-content;
            }
            display: block;
        }
    }
}
