#cart-confirm-page {
    text-align: center;
    padding: 100px 20px;

    i {
        font-size: 60px;
        color: #239a1b;
        border: 2px solid #239a1b;
        width: 100px;
        height: 100px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-bottom: 50px;

        &.fa-times {
            color: red;
            border-color: red;
        }
    }
}
