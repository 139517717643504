@import '../../../../variables';

#shipping {
    .method {
        border: 1px solid #eeeeee;
        border-radius: 10px;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        margin-bottom: 10px;

        &:hover {
            background-color: #efefef;
        }

        img {
            height: 25px;
        }

        .cost {
            margin-inline-start: auto;
            color: $primaryColor;
        }
    }

    div {
        margin-left: 10px;
    }
}
