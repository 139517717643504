#admin-bar {
    padding: 0;
    align-items: stretch;
    text-transform: capitalize;

    .home-btn {
        border-radius: 0;
        padding: 7px 11px;
    }

    h6 {
        margin: 0;
        font-weight: 500;
    }

    .nav-link {
        transition: background-color 0.3s;
        color: #fff;
        padding-right: 13px;
        padding-left: 13px;

        &:hover {
            background-color: #fff;
            color: #000;
        }
    }

    .nav-item {
        .show a {
            background-color: #fff;

            &:focus {
                color: #1b6aff;
            }
        }

        &.show .nav-link {
            background-color: #fff;
            color: #000;
        }

        .fa-chevron-down {
            font-size: 10px;
            margin: 0 5px;
        }
    }

    .nav-item > a,
    .nav-link {
        font-weight: 200;
    }

    .dropdown-toggle:after {
        display: none;
    }

    .dropdown-menu {
        margin: 0;
        padding: 0;
        border: 0;
        border-radius: 0;
        box-shadow: 1px 6px 8px rgba(0, 0, 0, 0.2);
        text-align: left;

        .dropdown-item {
            padding: 5px 25px;
        }
    }

    .apps-dropdown {
        &.sub-apps {
            background-color: #000000;
        }

        .nav-item {
            &.dropdown {
                display: flex;
            }

            &.show a {
                background-color: #1b6aff;
                color: #fff;
            }

            i {
                margin-right: 5px;
                font-size: 11px;
            }
        }

        .dropdown-menu.show {
            background-color: #1b6aff;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;

            .dropdown-item {
                text-align: center;
                clear: none;
                color: #ffffff;
                display: flex;
                padding: 10px;
                transition: background-color 0.3s;

                &:hover {
                    background-color: #edd5ba;
                    color: #000;
                }

                i {
                    font-size: 20px;
                    margin-left: 10px;
                }
            }
        }
    }

    @media print {
        display: none;
    }
}

#admin-nav {
    .dropdown-toggle::after {
        float: left;

        @media (min-width: 768px) {
            float: none;
        }
    }

    .setting-btn {
        margin-left: auto;
        flex-direction: row;
        align-items: stretch;
        justify-content: center;
        margin-top: 20px;

        @media (min-width: 768px) {
            align-items: center;
            margin-top: 0;
        }

        a {
            border: 1px solid #c9e8f4;
            color: #c9e8f4;
            padding: 3px 10px;
            font-size: 12px;
            margin-right: 5px;
        }

        i {
            margin-left: 5px;
            float: right;

            @media (min-width: 768px) {
                margin-left: 0;
                float: none;
            }
        }
    }
}

.rtl #admin-bar {
    .dropdown-menu {
        text-align: right;
    }

    .setting-btn {
        margin-left: 0;
        margin-right: auto;

        a {
            margin-right: 0;
            margin-left: 5px;
        }
    }
}
