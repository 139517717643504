@import '../../../variables.scss';

#cart-page {
    background-color: transparent;
    min-height: 500px;
    padding: 10px;

    h1 {
        margin: 10px 0;
        font-size: 18px;
        font-weight: 600;

        span {
            font-weight: 300;
            font-size: 14px;
        }
    }
    i {
        margin-inline-end: 2px;
    }
    .alert {
        margin: 10px 20px;
        padding: 10px 20px;
        font-size: 14px;
        color: black;
        font-weight: 500;
    }

    .products-list {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .product {
            // #f7f9fe
            background-color: #f7f9fe;
            border-radius: 8px;
            overflow: hidden;
            align-items: center;
            position: relative;
            padding: 12px 24px;

            .card-info-wrapper {
                display: flex;
                gap: 16px; // width: 100%;
                .image {
                    background: center no-repeat;
                    background-size: contain;
                    height: 120px;
                    // min-width: 180px;
                }

                .info-side {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    padding: 0 0px;

                    .data {
                        display: flex;

                        gap: 8px;
                        width: 100%;
                        justify-content: space-between;
                        margin-bottom: 20px;

                        @media screen and (max-width: 768px) {
                            flex-direction: column;
                        }

                        .details {
                            .badges {
                                display: flex;
                                flex-wrap: wrap;
                                gap: 2px;
                                margin-bottom: 8px;
                            }
                            h3 {
                                font-size: 20px;
                                font-weight: 500;
                                color: $primaryColor;
                            }
                            .old-price {
                                font-size: 12px;
                                color: red;
                                text-decoration: line-through;
                            }
                        }
                        .price-box {
                            // display: flex;
                            font-weight: 500;

                            .price-wrapper {
                                margin-bottom: 8px;
                                @media screen and (max-width: 768px) {
                                    display: flex;
                                    gap: 8px;
                                    align-items: center;
                                }
                                .price {
                                    font-size: 18px;
                                    font-weight: 600;
                                }
                                .old-price {
                                    font-size: 12px;
                                    color: red;
                                    text-decoration: line-through;
                                }
                            }
                            .savings-wrapper {
                                span {
                                    display: none;
                                }
                                @media screen and (max-width: 768px) {
                                    span {
                                        display: inline;
                                    }
                                    display: flex;
                                    // gap: 6px;
                                    align-items: center;
                                }
                                .saved-amount {
                                    font-size: 12px;
                                    font-weight: 600;
                                    color: $accentColor;
                                }
                            }

                            .note {
                                color: #aaaaaa;
                                font-size: 10px;
                            }
                        }
                    }
                    .controls {
                        display: flex;
                        width: 100%;
                        gap: 8px;
                        @media screen and (max-width: 768px) {
                            align-items: center;
                            justify-content: start;
                        }
                        .quantity {
                            clear: both;
                            display: flex;

                            span {
                                padding: 7px;
                                font-weight: 600;
                                @media screen and (max-width: 768px) {
                                    display: none;
                                }
                            }

                            // .form-control {
                            //     // background-color: #10c600;
                            //     border-color: $accentColor;
                            //     border-radius: 0;
                            //     width: 60px;
                            //     text-align: center;
                            //     padding: 0;
                            // }
                            input[type='number']::-webkit-outer-spin-button,
                            input[type='number']::-webkit-inner-spin-button {
                                -webkit-appearance: none;
                                margin: 0;
                            }

                            /* Firefox */
                            input[type='number'] {
                                appearance: textfield;
                                -moz-appearance: textfield;
                            }
                            input {
                                border-color: $primaryColorLight;
                                border-radius: 8px;
                                // border-radius: 0;
                                width: 60px;
                                text-align: center;
                                padding: 4px 0;
                            }
                        }
                        .delete {
                            background-color: transparent;
                            color: $primaryColorLightText;
                            border-color: $primaryColorLightText;
                            @media screen and (max-width: 768px) {
                                font-size: 12px;
                            }
                            // i {
                            //     color: $primaryColorLight;
                            // }
                            // border: 0;
                        }
                    }
                }
            }
        }
        .gift {
            background-color: $extraColor1;
        }
    }

    #cart-summary {
        margin: 20px 10px;
        padding: 20px;
        border-radius: 10px;
        border: 1px solid $primaryColorLight;
        display: flex;
        gap: 8px;
        flex-direction: column;
        background-color: white;

        @media (min-width: 768px) {
            width: 50%;
            margin: 20px 0px;
        }
        #total {
            padding: 20px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            // margin: 10px 0 30px;

            @media (min-width: 768px) {
                // background-color: #f3f4f8;
                margin-bottom: 0;
            }

            div {
                width: 50%;
                font-size: 20px;
                font-weight: 600;

                @media (min-width: 768px) {
                    font-size: 25px;
                }
            }

            .saved {
                font-size: 15px;
                color: $accentColor;
            }
        }

        .actions {
            // display: flex;
            // width: 100%;
            a {
                box-sizing: content-box;
                text-align: center;
                display: block;
                border: 2px solid $accentColor;
                // background-color: #10c600;
                // #10c600
                background-color: $accentColor;
                color: white;

                border-radius: 5px;
                padding: 10px 20px;
                font-size: 17px;
                transition: background-color 0.5s;

                &:hover {
                    background-color: transparent;
                    color: $accentColor;
                }
            }
        }
    }
}
