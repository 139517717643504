@import '../../../../variables.scss';

.cities-carousel-block {
    background-color: #fff;
    padding: 15px;
    position: relative;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
    margin-bottom: 10px;

    h3 {
        font-weight: 600;
        font-size: 23px;
        margin-bottom: 25px;
        margin-left: 2%;
    }

    .buttons {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 0;
        color: $primaryColor;
        font-size: 20px;
        pointer-events: none;
        margin-top: -25px;

        .right {
            float: right;
        }

        i {
            padding: 5px;
            cursor: pointer;
            pointer-events: auto;
            float: left;
        }
    }

    li {
        padding: 0 5px;
    }

    .alice-carousel {
        width: 96%;
        margin: 0 2%;
    }

    .product-box-block {
        border: 0;
        padding: 0;
    }

    .placeholder {
        display: block;
    }

    .city {
        background-color: $accentColor;
        height: 100px;
        color: black;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        font-size: 17px;
    }
}

.rtl .cities-carousel-block {
    .buttons {
        right: auto;
        direction: ltr;
    }

    .alice-carousel__stage-item {
        direction: rtl;
    }
}
