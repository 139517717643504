.menu-block {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        justify-content: space-between;

        li {
            padding: 10px;
        }
    }

    img {
        width: 30px;
    }
}
