@import '../../../../variables';

.commerce-categories-block {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;

        li {
            padding: 9px;
        }
    }

    a {
        display: flex;
        align-items: center;
    }

    img {
        width: 30px;
    }

    i {
        margin-right: 10px;
        font-size: 30px;
        line-height: 20px;
        display: inline-block;
    }
}

.rtl .commerce-categories-block i {
    margin-right: 0;
    margin-left: 10px;
}

.main-menu {
    position: relative;

    @media (min-width: 768px) {
        &.active .dim {
            background-color: rgba(0, 0, 0, 0.5);
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            transition: background-color 0.5s;
        }
    }

    li {
        flex-grow: 1;
        padding: 0;
        transition: background-color 0.5s;

        @media (min-width: 768px) {
            padding: 10px;
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
            color: #efefef;
        }

        i {
            font-size: 15px;
        }
    }

    img {
        margin-left: 5px;
    }

    a {
        padding: 10px 0;
        color: $primaryColorText;
        transition: color 0.5s;

        &:hover {
            color: #efefef;
        }
    }

    @keyframes fade_in_show {
        0% {
            opacity: 0;
            top: 90%;
        }
        100% {
            opacity: 1;
            top: 100%;
        }
    }

    @media (min-width: 768px) {
        &.active ul.level-1 > li:hover ul.level-2 {
            opacity: 1;
            top: 100%;
            animation: fade_in_show 0.5s;
            display: block;
        }
    }

    ul.level-1 {
        position: relative;
        display: block;
        z-index: 3;

        @media (min-width: 768px) {
            display: flex;

            ul {
                display: none;
            }
        }

        > li {
            display: flex;
            justify-content: center;
            align-items: center;

            > a {
                font-weight: 600;
                justify-content: center;
            }
        }
    }

    ul.level-2 {
        background-color: #fff;
        width: 100%;
        display: block;

        @media (min-width: 768px) {
            min-height: 500px;
            position: absolute;
            top: 90%;
            left: 0;
            z-index: 9999;
            display: none;
            opacity: 0;
        }

        li {
            margin: 0 10px;
            border-bottom: 1px solid #dddddd;

            @media (min-width: 768px) {
                width: 250px;
                margin: 0;
            }

            &:hover {
                & > ul {
                    display: block !important;
                }

                & > a {
                    color: $accentColor;
                }
            }

            &.has-children > a:after {
                font-family: 'Font Awesome 5 Free';
                font-weight: 900;
                content: '\f054';
                margin-left: auto;
            }
        }

        a {
            color: #555555;

            &:hover {
                color: $accentColor;
            }
        }

        .category-offer {
            width: 50%;
            border: 0;
            position: absolute;
            top: 50px;
            right: 2%;
            z-index: -1;

            img {
                width: auto;
            }

            a:after {
                display: none;
            }

            &:hover,
            a {
                background: none;
            }
        }
    }

    ul.level-3,
    ul.level-4 {
        background-color: #eeeeee;
        height: 100%;
        position: absolute;
        top: 0;
        left: 250px;
        display: none;

        @media (min-width: 768px) {
            display: block;
        }
    }

    ul.level-4 {
        background-color: #dddddd;

        ul {
            display: none !important;
        }
    }
}

.rtl .main-menu {
    ul.level-2 {
        li.has-children > a:after {
            content: '\f053';
            margin-right: auto;
            margin-left: 0;
        }
        .category-offer {
            right: auto;
            left: 20%;
        }
    }
    ul.level-3,
    ul.level-4 {
        left: auto;
        right: 250px;
    }
}

#categories-page {
    ul {
        display: block;
    }

    a {
        border-bottom: 1px solid #ddd;
        padding: 5px;
    }

    .level-1 > li > a {
        color: $accentColor;
        font-size: 17px;
    }

    i {
        font-size: 14px;
    }

    .category-offer,
    .level-2 i {
        display: none;
    }
}

.home-categories {
    margin: 20px 0;

    ul {
        display: flex;
        justify-content: stretch;

        li {
            padding: 0;
            flex: 1;
        }
    }

    a {
        display: block;
        text-align: center;
    }

    i {
        background-color: $accentColor;
        color: #fff;
        display: block;
        padding: 25px 0;
        font-size: 35px;
        margin-bottom: 10px;
    }

    .level-2 {
        display: none;
    }
}
