@import '../../../variables.scss';

#commerce-orders-pages {
    .order {
        background-color: #ffffff;
        color: #555555;
        border: 1px solid #dddddd;
        transition: background-color 0.5s;
        margin: 0 30px 10px;
        padding: 20px;
        display: block;

        &:hover {
            background-color: #eeeeee;
        }

        .details {
            margin-bottom: 20px;
        }

        .date {
            text-align: left;
        }

        i {
            margin-left: 5px;
            color: $primaryColor;
        }
    }

    .pagination {
        margin-top: 30px;
        justify-content: center;
    }

    // for ticketing
    //.item:not(:first-child) {
    //	margin-top: -760px !important;
    //	position: relative;
    //	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    //	border: 1px solid #dddddd;
    //	transition: margin-top 0.5s;
    //
    //	@media (min-width: 768px) {
    //		margin-top: -340px !important;
    //	}
    //}
    //
    //.items:hover .item {
    //	margin-top: 20px !important;
    //}

    //@media (max-width: 767px) {
    //	.item .ticket-info td {
    //		width: 100%;
    //		display: block;
    //		text-align: center;
    //		border: 0 !important;
    //	}
    //}
}
