@import '../../../../variables.scss';

.products-list-block {
    background-color: #fff;
    padding: 15px;
    position: relative;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;

    h3 {
        font-weight: 600;
        font-size: 23px;
        margin-bottom: 25px;
        flex-grow: 1;
        width: 100%;
    }

    .product-box-block {
        width: 48%;
        margin: 1%;

        @media (min-width: 768px) {
            width: 23%;
        }
    }

    .placeholder {
        width: 48%;
        margin: 1%;
    }
}

.product_states {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;

    &__item {
        flex-grow: 1;
        margin-bottom: 8px;
        
        > div{
    
            &.product_states--1active,
            &.product_states--2inactive {
                background-color: #3775df;
            }

           &.product_states--3total  {
               background-color: #a4c6e4;
           }
       
           &.product_states--4unavailable ,
           &.product_states--5available {
               background-color: #608BC1;
           }
       }
    }
    

}
