@import '../../../../variables.scss';

.seller-box-block {
    box-sizing: border-box;
    height: 100%;
    background-color: #ffffff;
    padding: 5px;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    transition: border-color 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    // min-height: 350px;

    &:hover {
        background-color: #fbfbfb;
    }
    a {
        &:hover {
            color: $primaryColor;
        }
    }
    .top-section {
        display: flex;
        position: relative;
        justify-content: center;
        box-sizing: border-box;
        background-color: #f8f8f9;
        padding: 4px;
        margin-bottom: 16px;
        height: 250px;
        overflow: hidden;

        // flex: 1;

        .image {
            width: 100%;
            height: auto;
            object-fit: contain;
            display: flex;
            justify-content: center; // Center the image horizontally
            align-items: center; // Center the image vertically
            transition: transform 0.2s ease;
            background-size: contained;
        }
    }

    .bottom-section {
        width: 100%;
        padding: 0 4px;
        height: 80px;

        .title {
            display: -webkit-box; /* Enable flexbox for WebKit browsers */
            line-clamp: 2;
            -webkit-line-clamp: 2; /* Number of lines to show */
            -webkit-box-orient: vertical; /* Set box orientation to vertical */
            overflow: hidden; /* Hide the rest of the content */
            text-overflow: ellipsis; /* Add ellipsis after the specified lines */
            white-space: normal;
            font-size: 14px;
        }
    }

    @media (max-width: 750px) {
        .top-section {
            height: 220px;
        }
        .bottom-section {
            // height: 40px;
            .title {
                margin: 10px 0;
                font-size: 12px;
                margin-bottom: 0;
            }
        }
    }
}
