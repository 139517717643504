@import '../../variables.scss';

#admin-area {
    background-color: #efefef;
    flex: auto;

    .admin-header-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
        border-bottom: 1px solid #efefef;
        margin: 5px 0;

        h1 {
            color: black;
            padding: 0;
            text-transform: capitalize;
        }

        .btn {
            border-radius: 0;
        }
    }

    .form-label {
        text-transform: capitalize;
    }
}
