@import '../../../variables.scss';

#commerce-compare-page {
    padding-bottom: 70px;
    min-height: 500px;

    .products {
        display: flex;
        justify-content: center;
    }

    h1 {
        font-size: 20px;
        margin-top: 20px;
    }

    #reset {
        background-color: $accentColor;
        margin: 20px auto;
        display: block;
        border: 0;
    }

    .product-box-block {
        flex-grow: 1;
        flex-basis: 0;
        border-radius: 0;
        margin: 0.5%;
        padding: 5px;

        h4 {
            font-size: 14px;
            height: 50px;
            overflow: hidden;
        }
    }

    .attribute {
        border-top: 1px solid #dddddd;
        padding-top: 10px;
        margin-top: 5px;
        text-align: right;

        span {
            color: $primaryColor;
        }
    }

    .remove {
        position: absolute;
        top: 0;
        left: 0;
        padding: 10px;
        cursor: pointer;

        &:hover {
            color: $accentColor;
        }
    }
}
