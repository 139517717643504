#favorites-page {
    min-height: 500px;
    padding-bottom: 70px;

    .page-body {
        flex-wrap: wrap;

        .product-box-block {
            width: 32%;
            margin: 0.5%;
        }
    }
}
