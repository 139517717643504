@import '../../../variables.scss';

#cart-shipping-page {
    text-align: center;
    padding: 0 0 10px 0;

    h1 {
        display: flex;
        align-items: center;
        gap: 8px;
        text-align: start;
        margin-top: 16px;
        margin-inline-start: 14px;
    }

    

    .method {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        margin-bottom: 10px;
        .title {
            display: flex;
            align-items: center;
        }
        img {
            width: 50px;
            margin: 0 10px;
        }

        .cost {
            color: $accentColor;
            font-size: 20px;
            font-weight: 500;
        }
    }

    .list-group-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .list-group {
        margin: 20px;
        width: 100%;
        max-width: 32rem;
    }

    .spinner-border {
        margin: 100px 0;
    }

    #continue {
        background-color: #36c5f3;
        border: 0;
        display: block;
        width: 50%;
        margin: 30px auto;
        padding: 10px;
        font-size: 20px;
        border-radius: 40px;
    }
}
