@import '../../../../variables.scss';

.favorites-popup-block {
    button.btn.btn-primary {
        background-color: transparent;
        border: 0;
        position: relative;
        color: #000;

        &:hover,
        &:active {
            background-color: transparent;
            color: $accentColor;
        }

        &:after {
            display: none;
        }

        svg {
            width: 20px;
            margin: 0 5px;
            fill: currentColor;
        }

        .badge {
            background-color: #74d7ff;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            line-height: 15px;
            font-size: 8px;
            padding: 0;
            text-align: center;
            position: absolute;
            top: 0;
            right: 5px;
        }
    }

    .dropdown-menu {
        width: 300px;
        box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.2);
        padding: 7px;
        border: 0;
        max-height: 500px;
        overflow: auto;
    }

    .dropdown-item {
        display: flex;
        overflow: hidden;
        padding: 0 0 5px;
        margin-bottom: 5px;
        font-size: 12px;
        border-bottom: 1px solid #eeeeee;
        white-space: normal;

        .image {
            background: center no-repeat;
            background-size: contain;
            width: 100px;
            height: 100px;
            flex-shrink: 0;
            margin-left: 5px;
        }
    }

    .empty {
        font-size: 10px;
        text-align: center;
        color: #aaaaaa;
    }
}

.rtl .favorites-popup-block > .btn {
    flex-direction: row-reverse;
    display: flex;
}
