@import '../../../variables.scss';

#place-order-page {
    min-height: 500px;
    padding-bottom: 50px;

    @media (max-width: 767px) {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    h1 {
        font-size: 20px;
        font-weight: 600;

        span {
            font-weight: 300;
        }

        i {
            margin-right: 5px;
        }
    }
    h3 {
        font-size: 14px;
        color: $primaryColor;
        vertical-align: middle;
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 8px;

        @media (min-width: 768px) {
            font-size: 17px;
        }

        span {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
        }
    }
    #steps {
        margin: 40px 20px 40px;
        color: #abb4cb;
        font-size: 10px;
        display: none;

        @media (min-width: 768px) {
            display: flex;
        }

        .step {
            position: relative;

            div {
                background-color: transparent;
                margin-bottom: 5px;
                border: 2px solid #abb4cb;
                color: #abb4cb;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            span {
                position: absolute;
                width: 100px;
                text-align: center;
                left: 0;
                bottom: -20px;
                margin-left: -30px;
                color: $primaryColor;
            }

            &.active div {
                background-color: $primaryColor;
                border-color: $primaryColor;
                color: #ffffff;
            }
        }

        .line {
            flex: 1;
            text-align: center;
            margin-top: 8px;
            font-size: 7px;

            @media (min-width: 768px) {
                font-size: 10px;
            }

            &:after {
                content: '';
                display: block;
                border: 1px dashed #dddddd;
                margin-top: 10px;
            }

            &.active:after {
                border: 2px solid $primaryColor;
            }
        }
    }
    #products {
        border: 1px solid #dddddd;
        border-radius: 10px;
        padding: 15px 0;

        .products-list {
            // padding-bottom: 20px;
            padding: 15px 20px;
            display: flex;
            gap: 15px;
            overflow-x: auto;

            // flex-wrap: wrap;

            .product {
                width: 300px;
                min-width: 300px;
                min-height: 120px;
                overflow: hidden;

                .content-wrapper {
                    display: flex;
                    gap: 15px;
                    align-items: flex-start;
                    .image-wrapper {
                        min-width: 80px;
                        height: 100%;
                        img {
                            background: center no-repeat;
                            object-fit: cover;
                            width: 100%;
                            height: auto;
                        }
                    }
                    .product-info {
                        .quantity-tag {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            width: 40px;
                            height: 22px;
                            font-size: 11px;
                            font-weight: 600;
                            padding: 2px 5px;
                            border-radius: 16px;

                            background-color: #9ba0b1;
                            color: white;
                        }
                        .name {
                            margin-top: 8px;
                            font-size: 14px;
                        }
                        .line-truncate {
                            display: -webkit-box; /* Fallback to support the older flexbox model */
                            -webkit-box-orient: vertical; /* Orientation of the box model */
                            line-clamp: 2; /* Number of lines to show */
                            -webkit-line-clamp: 2; /* Number of lines to show */
                            overflow: hidden; /* Hide any overflow text */
                            text-overflow: ellipsis; /* Display ellipses at the end of overflowed text */
                            white-space: normal; /* Make sure text can wrap */
                        }
                        .price {
                            font-size: 16px;
                            font-weight: 600;
                        }
                        .old-price {
                            margin-inline-start: 4px;
                            font-size: 12px;
                            color: red;
                            text-decoration: line-through;
                        }
                    }
                }
                // .price-options-styles {
                //     //create a bottom shadow
                //     // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
                //     // border: 1px solid #ddd;
                //     width: fit-content;
                //     padding: 5px;
                // }
                h5 {
                    margin: 8px 0 0 0;
                    font-size: 12px;
                    font-weight: 600;
                    color: red;
                }
                &.error {
                    background-color: #ffeaed;
                    border-color: red;
                    color: red;
                }
            }
        }
        .cart-alert {
            margin: 0 20px;
        }
    }

    .step div,
    h3 span {
        background-color: $accentColor;
        width: 40px;
        height: 40px;
        border-radius: 40px;
        text-align: center;
        color: #ffffff;
        font-size: 17px;
        font-weight: 600;
    }

    #contact-info {
        background-color: #f3f4f8;
        padding: 20px 20px;

        border-radius: 10px;
    }

    #shipping {
        padding: 10px 20px;
        border-radius: 10px;
    }

    #locations-wrapper {
        padding: 10px 20px;
        border-radius: 10px;
    }
    #address-wrapper {
        padding: 20px;
    }

    #new-address {
        margin-top: 15px;
        margin-bottom: 15px;

        .switch-wrapper-label {
            display: flex;
            // align-items: center;
            font-weight: 500;
            gap: 3px;
        }
        .error {
            color: red;
            padding: 5px;
        }
    }
    .payments-coupon-wrapper {
        // background-color: #f3f4f8;
        padding: 10px 20px;
        // margin: 15px 0;
        border-radius: 10px;

        #payment {
            .method {
                border: 1px solid #eeeeee;
                border-radius: 10px;
                padding: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;
                flex-wrap: wrap;

                &:hover {
                    background-color: #efefef;
                    cursor: pointer;
                }

                img {
                    height: 25px;
                }

                .cost {
                    margin-right: auto;
                    color: $primaryColor;
                }

                .payment-hook {
                    width: 1000px;
                    display: none;

                    > div {
                        background-color: transparent;
                    }
                }

                &.selected .payment-hook {
                    display: block;
                }
            }

            div {
                margin-left: 10px;
            }
        }

        #coupon {
            margin: 20px;
            padding: 20px 10px;
            background-color: $extraColor1;
            border-radius: 10px;

            @media (min-width: 800px) {
                margin: 18px;
            }
            .content {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                gap: 10px;
                justify-content: flex-start;
                align-items: center;
                .success {
                    color: yellowgreen;
                    padding: 5px;
                }

                .error {
                    color: red;
                    padding: 5px;
                }
            }
        }
    }

    .points_win {
        padding: 20px;
        color: #000;
        background-color: greenyellow;
        margin: 20px 0;
    }

    #summary {

        margin: 10px 10px;
        padding: 20px;
        border-radius: 10px;
        border: 1px solid $primaryColorLight;
        display: flex;
        gap: 8px;
        flex-direction: column;
        background-color: white;

        @media (min-width: 800px) {
            // width: 50%;
            margin: 10px 0px;
        }

        #coupon {
            // margin: 20px;
            padding: 20px 10px;
            background-color: $extraColor1;
            border-radius: 10px;

            margin-bottom: 10px;

            .content {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                gap: 10px;
                justify-content: flex-start;
                align-items: center;
                .success {
                    color: yellowgreen;
                    padding: 5px;
                }

                .error {
                    color: red;
                    padding: 5px;
                }
            }
        }

        a {
            color: blue;
            font-weight: 600;
            margin-inline-start: 4px;
        }

        #total {
            // padding: 20px 20px;
            border-radius: 10px;
            // display: inline-flex;
            display: flex;
            
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            .points {
                background-color: $extraColor1;
                background-color: $extraColor1;
                width: 100%;
                padding: 12px ;
                border-radius: 8px;
                margin-bottom: 18px;
                margin-inline-start: 0;

            }
            h6 {
                margin: 0;
                padding: 0 20px;
            }
            
        }
        .terms-conds {
            padding: 10px 20px;
        }

        .continue {
            margin-top: 10px;
            box-sizing: content-box;
            text-align: center;
            display: block;
            border: 2px solid $accentColor;
            // background-color: #10c600;
            // #10c600
            background-color: $accentColor;
            color: white;

            border-radius: 5px;
            padding: 10px 20px;
            font-size: 17px;
            transition: background-color 0.5s;

            &:hover {
                background-color: transparent;
                color: $accentColor;
            }

            // background-color: #36c5f3;
            // border: 0;
            // display: block;
            // // margin-top: 40px;
            // padding: 10px 30px;
            // font-size: 20px;
            // border-radius: 40px;
        }
        // .review {
        //     background-color: $extraColor1;
        //     border-color: $extraColor1;
        //     color: black;
        //     &:hover {
        //         background-color: transparent;
        //         color: black;
        //         border: 1px solid $extraColor1;
        //     }
        // }
    }

    .form-group {
        background-color: #fff;
        border: 1px solid #e9ebf2;
        margin: 10px;
        flex: 1;
        position: relative;

        label {
            color: #b4b9d1;
            padding: 5px 10px 0;
            margin: 0;
            display: block;
        }

        input {
            border: 0;
            padding-top: 0;
        }

        .form-control:disabled {
            background-color: transparent;
            color: #aaaaaa;

            & + .error {
                display: none;
            }
        }

        .error {
            color: red;
            position: absolute;
            top: 5px;
            right: 5px;
        }
    }

    .alert {
        display: flex;

        div {
            width: 33%;
        }

        h4 {
            font-size: 13px;
        }
    }

    &.order-review {
        input,
        select {
            background: transparent;
            border: 0;
            padding: 0;
            pointer-events: none;
        }
        #contact-info {
            background: transparent;
            border: 1px solid #f0f0f0;
            border-width: 1px 0 1px 0;
        }
        h3 span {
            background: #dddddd;
            color: #000;
        }
        #addresses,
        #shipping,
        #payment {
            .address,
            .method {
                display: none;
            }
            .selected {
                display: flex;
                border: 0;
                background-color: transparent;

                &:hover {
                    cursor: auto;
                    background-color: transparent;
                }

                img {
                    display: none;
                }
            }
        }
        #coupon {
            // display: none;
        }
        #address-wrapper {
            .new-address-button {
                display: none;
            }
            #addresses {
                .address {
                    display: none;
                }
                .address.selected {
                    display: block;
                    border: none;
                    cursor: default;
                }
            }
        }
    }
}
