@import '../../../../variables.scss';

#address-wrapper {
    margin-bottom: 30px;
    // padding: 20px;
    border-radius: 10px;

    h3 {
        font-size: 14px;
        color: $primaryColor;
        vertical-align: middle;
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 8px;
        margin-top: 2px;

        @media (min-width: 768px) {
            font-size: 17px;
        }

        span {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
        }
    }

    #addresses {
        //
        max-height: 300px;
        overflow: auto;
        margin-bottom: 20px;

        .address {
            background-color: #fff;
            border: 2px solid #eee;
            padding: 15px;
            margin-bottom: 5px;
            cursor: pointer;
            display: flex;
            align-items: center;

            &:hover {
                background-color: #f3f4f8;
            }

            &.selected {
                // background-color: #f3f4f8;
                border-color: $accentColor;
            }

            &.new {
                color: $primaryColor;
            }
        }
    }
    .error {
        color: red;
        padding: 5px;
        font-size: 12px;
    }
    .new-address-button {
        margin-top: 12px;
        background-color: #fff;
        color: $accentColor;
        width: fit-content;
        padding: 8px 8px;
        border-radius: 5px;
        cursor: pointer;
        border: 1px solid $accentColor;
        font-weight: 600;
    }

}
.edit-address-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    width: 90%; 
    max-width: 600px;
}
