@import '../../../../variables.scss';

.search-block {
    .form-control {
        background-color: #dddddd;
        border-radius: 0 !important;
        border: 0;

        &:focus {
            background-color: #cecece;
            box-shadow: none;
            border-color: #cecece;
        }
    }

    .btn {
        background-color: $primaryColor;
        border-radius: 0 !important;
        border: 0;
        padding: 0 10px;
        color: $primaryColorText;

        &:focus {
            box-shadow: none;
        }

        i {
            font-size: 18px;
            vertical-align: bottom;
        }
    }

    .auto-complete {
        background-color: #fff;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
        width: 100%;
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 10;

        a {
            padding: 5px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #eeeeee;

            &:hover {
                background-color: #eeeeee;
            }
        }

        .image {
            background-size: contain;
            width: 50px;
            height: 50px;
            border: 1px solid #eeeeee;
            padding: 5px;
            margin-left: 10px;
        }
    }
}
