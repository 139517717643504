@import '../../../../variables.scss';

.brands-carousel-block {
    background-color: #fff;
    padding: 15px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
    position: relative;
    margin-top: 10px;

    h3 {
        font-weight: 600;
        font-size: 23px;
    }

    img {
        transition: transform 0.5s;
        transform: scale(0.9);
    }

    li:hover img {
        transform: scale(1);
    }

    .buttons {
        position: absolute;
        top: 0;
        left: 5px;
        color: $primaryColor;
        font-size: 30px;

        i {
            padding: 5px;
            cursor: pointer;
        }
    }
}
