@import '../../../../variables.scss';

#locations-wrapper {
    // padding: 20px;
    border-radius: 10px;
    margin-bottom: 30px;
    #addresses {
        max-height: 300px;
        overflow: auto;

        .address {
            background-color: #fff;
            border: 2px solid #eee;
            padding: 15px;
            margin-bottom: 5px;
            cursor: pointer;
            display: flex;
            align-items: center;

            &:hover {
                background-color: #f3f4f8;
            }

            &.selected {
                // background-color: #f3f4f8;
                border-color: $accentColor;
            }

            &.new {
                color: $primaryColor;
            }
        }

        button {
            margin-top: 10px;
            border-radius: 50px;
            padding: 10px 30px;
            border: 0;
        }
    }
}
