@import '../../../../variables.scss';

.carousel-block {
    background-color: #fff;
    padding: 15px;
    position: relative;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
    margin-top: 10px;

    h3 {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 5px;
        margin-left: 2%;
    }

    .buttons {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 0;
        color: $primaryColor;
        font-size: 20px;
        pointer-events: none;
        margin-top: -25px;

        .right {
            float: right;
        }

        i {
            padding: 5px;
            cursor: pointer;
            pointer-events: auto;
            float: left;
        }
    }

    li {
        padding: 0 5px;
    }

    .alice-carousel {
        width: 96%;
        margin: 0 2%;
    }

    .placeholder {
        display: block;
    }

    @media (min-width: 750px) {
        &.extended {
            margin: 0;

            .box-block {
                &:hover .image {
                    transform: none;
                }

                .box-info {
                    display: flex;
                    justify-content: space-between;

                    .title {
                        color: #000;
                        font-size: 20px;
                        margin: 0 0 10px;
                    }

                    .image {
                        width: 59%;
                        height: auto;
                        flex-shrink: 0;
                    }

                    .details {
                        width: 40%;
                        background: none;
                        position: static;
                        padding: 0;
                    }

                    .description {
                        height: 290px;
                        overflow: hidden;
                        color: #aaaaaa;
                        text-align: justify;

                        h5 {
                            font-size: 12px;
                        }

                        line-height: 1.5;
                    }
                }
            }

            .buttons {
                top: 50%;
            }
        }
    }
}

.rtl .carousel-block {
    .buttons {
        right: auto;
        direction: ltr;
    }

    .alice-carousel__stage-item {
        direction: rtl;
    }
}
