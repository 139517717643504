.table__details {
    width: 40%;
    margin: 0 auto;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    @media (max-width: 768px) {
        width: 100%;
    }

    .table__card {
        width: 100%;
        margin-top: 16px;
        margin-bottom: 16px;
        padding: 32px 16px;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: rgba(255, 166, 0, 0.592);
        border: 2px solid rgba(255, 166, 0, 0.592);
        color: rgb(172, 113, 5);
        font-size: 18px;
    }

    .trnsaction__info {
        width: 100%;
        padding: 16px;
        &__label {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 8px;
            width: 100%;
            cursor: pointer;
        }
        &.active .trnsaction__info__content {
            width: 100%;
            display: unset;
        }
        &__content {
            display: none;
            .content__label {
                color: rgb(172, 113, 5);
            }
        }
        .left_to_pay {
            border-top: 1px solid gray;
            padding-top: 4px;
            margin-top: 32px;
        }
    }

    .table__feadback {
        padding: 16px;
        margin-top: 32px;
        font-size: 14px;
        text-align: center;
        margin-top: auto;
        width: 100%;
        .google__location {
            margin-top: 16px;
            padding: 6px 16px;
            border-radius: 8px;
            background-color: rgb(207, 203, 203);
            display: flex;
            align-items: center;
            gap: 16px;
            width: 80%;
            margin: 0 auto;
            img {
                width: 24px;
            }
        }
    }
}
