.slider-block {
    min-height: 100px;

    .carousel-control-next,
    .carousel-control-prev {
        width: 8%;
        opacity: 0.7;
        display: none;

        @media (min-width: 750px) {
            display: flex;
        }
    }

    img {
        position: absolute;
        display: block;
    }

    .active img {
        transform: translate(0, 0) !important;
        opacity: 1 !important;
    }

    .carousel-caption {
        right: 0;
    }

    .carousel-indicators {
        margin-bottom: 0;

        @media (min-width: 768px) {
            margin-bottom: 1rem;
        }
    }
}
