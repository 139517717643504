@import '../../../../variables.scss';

.language-switcher-block {
    a {
        color: #fff;

        &:hover {
            color: $primaryColorLight;
        }
    }
}
