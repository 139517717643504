@import '../../../variables.scss';

#sellers-page {
    text-align: center;
    padding-bottom: 150px;

    h1 {
        text-align: right;
    }

    .grid {
        display: flex;
        flex-wrap: wrap;
    }

    .seller {
        padding: 30px 0;
        width: 50%;
        color: $accentColor;

        img {
            width: 100px;
            height: 100px;
            border-radius: 10px;
        }
    }

    h5 {
        margin-top: 10px;
    }
}
