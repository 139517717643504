@import '../../../variables.scss';

#commerce-category-page {
    position: relative;
    padding: 10px 0 50px;

    @media (min-width: 768px) {
        margin: 40px auto;

        .filters-switcher {
            display: none;
        }
    }

    //
    #products-filters {
        background-color: #fff;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        padding-bottom: 10px;
        margin: 0 -5px 10px;
        display: none;

        @media (min-width: 768px) {
            display: block;
        }

        &.opened {
            display: block;
        }

        .filters-switcher {
            text-decoration: none;
            font-size: 20px;
            float: right;
            color: #000;
        }

        > h3 {
            background-color: $primaryColor;
            color: $primaryColorText;
            font-size: 18px;
            padding: 10px;

            i {
                font-size: 13px;
            }
        }

        .accordion {
            border: 0;

            .accordion-button {
                font-size: 15px;
                color: $primaryColor;
                padding: 10px;
            }

            .accordion-item:first-of-type {
                border-radius: 0;
            }

            .accordion-button:not(.collapsed) {
                background-color: transparent;
                box-shadow: none;
            }

            .accordion-body {
                padding: 10px 10px 0;
            }
        }

        .section {
            color: #999999;

            @media (min-width: 768px) {
                display: block;
            }

            &:last-child {
                border: 0;
            }

            .choice {
                display: flex;
                justify-content: space-between;

                input {
                    margin: 5px 5px 0 0;
                    display: block;
                }

                div {
                    width: 100%;
                    margin: 0;
                }
            }

            .all {
                color: $accentColor;
            }
        }
    }

    #categories-filter {
        a {
            display: block;
            padding: 3px 0;
        }

        .active {
            color: $accentColor;
        }

        .sub {
            margin-right: 16px;
            padding: 0;
            display: none;
        }

        .active + .sub {
            display: block;
        }
    }

    .head {
        width: 100%;
        font-size: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        padding: 0 5px;

        @media (min-width: 768px) {
            padding: 0;
        }

        .wrap {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }

        .filter {
            background-color: rgba(46, 140, 175, 0.3);
            border: 1px solid #34a4cd;
            border-radius: 15px;
            color: #34a4cd;
            font-size: 12px;
            padding: 2px 10px;
            margin: 5px 10px 0 0;
            cursor: pointer;
            display: flex;
            align-items: center;

            // tmp
            display: none;

            &:hover {
                background-color: rgba(46, 140, 175, 0.5);
            }

            i {
                margin-right: 5px;
            }
        }

        .clear-filters {
            color: #34a4cd;
            font-size: 12px;
            margin: 8px 10px 0 0;
            cursor: pointer;

            // tmp
            display: none;
        }

        .sort-by {
            font-size: 12px;
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            margin: 0 auto 0 10px;

            > span {
                display: none;
            }
        }

        .filters-switcher {
            margin-left: 5px;
        }

        .btn-outer-link {
            border: 1px solid #aaa;
        }
    }

    //
    #products-list {
        background-color: #fff;
        display: flex;
        flex-wrap: wrap;

        .product-box-block {
            width: 48%;
            margin: 0.75%;
            text-align: center;

            @media (min-width: 768px) {
                width: 23.5%;
            }
        }
    }

    .products-carousel-block {
        margin-top: 10px;
    }

    //
    #loading {
        background-color: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        color: #ffffff;
        font-size: 40px;
        text-align: center;
        padding-top: 200px;
    }

    #category-banner {
        background-position: center;
        background-size: cover;
        margin: 30px 0 45px;
        height: 150px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        button {
            background-color: rgba(0, 0, 0, 0.5);
            color: #999999;
            border: 0;
            border-radius: 10px;
            padding: 5px 30px;
            font-size: 20px;
            margin-right: 20px;
            transition: background-color 0.5s;
        }

        &:hover button {
            background-color: rgba(0, 0, 0, 0.8);
        }
    }

    .pagination {
        margin-top: 10px;
    }

    .range-slider {
        height: 20px;
        margin-top: 30px;
        z-index: 1;

        .track {
            background-color: #ddd;
            height: 10px;
            margin-top: 5px;
        }

        .track-0 {
            border-radius: 0 5px 5px 0;
        }

        .track-1 {
            background-color: #7bceef;
            border-radius: 5px;
        }

        .track-2 {
            border-radius: 5px 0 0 5px;
        }

        .thumb {
            background-color: #fff;
            width: 20px;
            height: 20px;
            cursor: pointer;
            position: relative;
            border-radius: 50%;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);

            span {
                position: absolute;
                font-size: 10px;
                top: -20px;
                left: 50%;
                transform: translate(-50%, 0);
            }
        }
    }

    /* list view */
    &.list-view #products-list .product-box-block {
        width: 100%;
        flex-direction: row;
        align-items: center;
        text-align: inherit;
        margin-bottom: 10px;

        .image {
            width: 150px;
        }

        .title {
            height: auto;
        }

        .box-info {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-grow: 1;
        }

        .add-to-cart {
            width: 100px;
            flex-shrink: 0;
            margin-left: 15px;

            .btn-primary {
                padding: 5px;
            }
        }

        .extra-actions {
            width: 100px;
        }
    }
}

// RTL
.rtl #commerce-category-page {
    #products-filters {
        .filters-switcher {
            float: left;
        }

        .section .choice input {
            margin: 5px 0 0 5px;
        }
    }

    .head .filters-switcher {
        margin: 0 5px 0 0;
    }
}

.active > .page-link,
.page-link.active {
    z-index: auto;
}
