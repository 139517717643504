@import '../../../variables.scss';

#subscribe-page {
    .tab-content {
        padding: 20px;
        min-height: 400px;
    }

    .form-group {
        margin-top: 40px;
    }

    #yearly {
        margin: 10px auto;
        font-size: 20px;
        justify-content: flex-end;
        align-items: center;
        display: flex;

        * {
            cursor: pointer;
        }

        span {
            padding: 0 5px;
        }

        .form-switch {
            padding-right: 3em;
        }

        .badge {
            padding: 3px 10px;
            font-size: 12px;
        }
    }

    // bundles
    #bundles {
        background-color: #eee;
        display: flex;

        button {
            background: none;
            margin: 20px 5px 0;
            padding: 10px;
            border: 0;
            border-radius: 10px 10px 0 0;
            font-size: 20px;
            color: $primaryColor;

            @media (min-width: 768px) {
                margin: 20px 10px 0;
                padding: 20px;
            }

            &.active {
                background-color: #fff;
                color: $accentColor;
            }
        }
    }

    // Plans
    #plans {
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        #selected-bundle {
            display: none;
            cursor: default;
            border-color: transparent;

            @media (min-width: 768px) {
                display: block;
            }

            .card-header {
                visibility: hidden;
            }

            h6 {
                color: $accentColor;
            }

            .feature {
                text-align: right;
            }
        }

        .card {
            width: 100%;
            margin: 10px 5px;
            cursor: pointer;
            border: 2px solid transparent;
            flex-grow: 1;

            @media (min-width: 768px) {
                width: auto;
            }

            &.active {
                background-color: #fff;
                border-color: $accentColor;
                transform: scale(1.03);
                z-index: 1;

                .btn {
                    background-color: $accentColor;
                    color: #fff;
                }
            }
        }

        .card-title {
            color: $accentColor;
        }

        .price {
            font-size: 30px;
        }

        .card-body {
            padding: 0;
        }

        .features {
            margin-bottom: 20px;

            h6 {
                background-color: rgba(0, 0, 0, 0.05);
                padding: 5px;
                color: $accentColor;

                @media (min-width: 768px) {
                    color: transparent;
                }
            }
            .feature {
                min-height: 31px;
                padding: 5px;

                @media (min-width: 768px) {
                    text-align: center;

                    strong {
                        display: none;
                    }
                }
            }
            i {
                width: 20px;
            }
            .fa-check {
                color: #02c700;
            }
        }

        .btn {
            background-color: $extraColor1;
            width: 100%;
            margin: 10px auto;
            padding: 10px;
            border: 0;
            display: block;
            color: #000;

            &:disabled {
                background-color: #fff;
                opacity: 1;
                padding: 5px;

                i {
                    color: #1dff00;
                    font-size: 20px;
                }
            }
        }
    }

    // Cards
    #cards {
        margin-top: 30px;

        .item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 2px solid #dddddd;
            border-radius: 15px;
            padding: 10px;
            margin-bottom: 10px;
            cursor: pointer;

            i {
                font-size: 30px;
                margin-left: 5px;
            }

            i:not(.general) {
                display: none;
            }

            &.card-brand-VISA {
                i.general {
                    display: none;
                }
                i.visa {
                    display: block;
                }
            }

            &.card-brand-MASTER {
                i.general {
                    display: none;
                }
                i.mastercard {
                    display: block;
                }
            }

            .bin {
                flex-grow: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                direction: ltr;
                font-size: 17px;

                .mask {
                    font-size: 25px;
                    margin-right: 5px;
                    height: 30px;
                }
            }
        }
    }

    // Actions
    .actions {
        margin-top: 50px;
        display: flex;

        .btn {
            padding: 5px 30px;
            font-size: 15px;
        }

        .btn-primary {
            margin-right: auto;
            background-color: $accentColor;
            border-color: $accentColor;
        }
    }

    .nav-pills {
        background-color: #efefef;

        a {
            display: flex;
            align-items: center;
            color: black;
            cursor: default;
            font-size: 20px;
            margin-top: 20px;

            &.active {
                color: #fff;

                span {
                    border-color: #ffffff;
                }
            }

            span {
                width: 30px;
                height: 30px;
                border: 2px solid black;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 10px;
            }
        }
    }

    .btn {
        border-radius: 0;
    }
}
