#safe-zone {
    background-color: #ffe769;
    text-align: center;
    position: relative;

    &[dir='ltr'] {
        video,
        img {
            left: auto;
            right: 0;
        }
    }

    h1 {
        background-color: transparent;
    }

    video,
    img {
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        display: block;
    }

    .wrap {
        width: 500px;
        max-width: 100%;
        padding: 20px;
        border-radius: 10px;
        z-index: 5;
        position: relative;
        display: inline-block;
    }

    .bar {
        background-color: rgba(0, 0, 0, 0.2);
        height: 30px;
        border-radius: 20px;
        margin: 30px 0 20px;
        display: flex;
        align-items: center;
        position: relative;
        direction: rtl;

        &.passed {
            background: linear-gradient(
                135deg,
                #03a9f4 25%,
                #4cdfff 25%,
                #4cdfff 50%,
                #03a9f4 50%,
                #03a9f4 75%,
                #4cdfff 75%,
                #4cdfff 100%
            );
            background-size: 20px;
            height: 20px;
            margin: 0 0 0 4px;
            position: absolute;
            top: 5px;
            left: 0;
            animation: queueBar 3s linear 5s infinite;

            @keyframes queueBar {
                from {
                    background-position: 0;
                }
                to {
                    background-position: 40px;
                }
            }

            span {
                text-shadow: 0 0 1px #000000;
            }
        }

        span {
            color: #ffffff;
            padding-right: 10px;
        }
    }

    .number {
        background-color: #fb41ca;
        border-radius: 0 5px 5px 0;
        padding: 0 5px;
        color: #ffffff;
        position: absolute;
        top: -26px;
        white-space: nowrap;

        &:after {
            background-color: #fb41ca;
            width: 1px;
            height: 56px;
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}
