@import '../../../../variables.scss';

.store-switcher-block {
    padding: 3px;

    .btn {
        background-color: transparent;
        border: 0;

        &:hover {
            background-color: transparent;
            color: $primaryColorLight;
        }
    }

    img {
        width: 20px;
        margin-right: 5px;
    }
}
