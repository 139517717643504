.is-business-switch-wrapper {
    .form-group-is_business {
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .big-switch .form-check-input {
        width: 40px;
        height: 20px;
        margin-bottom: 10px;
        cursor: pointer;
    }

    .big-switch .form-check-input:checked {
        background-color: #007bff; /* You can adjust this color as per your theme */
    }

    .big-switch-label {
        font-size: 17px;
        margin-inline-end: 5px;
        font-weight: 500;
    }
    .icon-is_business {
        font-size: 24px;

        margin-bottom: 10px;
    }
}
