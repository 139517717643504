#contact-info {
    // background-color: #f3f4f8;

    .row {
        flex-wrap: wrap;
    }

    .form-group {
        background-color: #fff;

        border: 1px solid #e9ebf2;
        margin: 1%;
        flex: auto;

        @media (min-width: 768px) {
            flex: 1;
        }
    }
}
