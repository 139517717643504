
:root {
	--body-color: #f3f4f8;
	--body-text: #040c13;
	--primary-color: #212121;
	--primary-text-color: #ffffff;
	--primary-light-color: lightgray;
	--primary-light-text-color: #000000;
	--accent-color: #1b6aff;
	--extra-color-1: #edd5ba;
	--extra-color-2: #f41095;
}

$bodyColor: var(--body-color);
$bodyText: var(--body-text);
$primaryColor: var(--primary-color);
$primaryColorText: var(--primary-text-color);
$primaryColorLight: var(--primary-light-color);
$primaryColorLightText: var(--primary-light-text-color);
$accentColor: var(--accent-color);
$extraColor1: var(--extra-color-1);
$extraColor2: var(--extra-color-2);


