#seats-map {
    overflow: auto;
    position: relative;

    &.disabled #map-container {
        filter: grayscale(1);
    }

    #map-countdown {
        margin: 10px;
    }
}
